$image-path = '../../img/top/'

.page-top .main
  // ==========================================================================
  // Top Common
  // ==========================================================================
  .heading-primary
    font-vw(46, 46)

    +media('pc')
      font-rem(30, 30)

  .heading-secondary
    font-vw(46, 46)

    +media('pc')
      font-rem(24, 24)

  .text-lead
    text-align center

  // ==========================================================================
  // Main Visual
  // ==========================================================================
  .hero
    margin-bottom _half(250)

    +media('pc')
      margin-bottom 119px

    [data-hero-el]
      visibility hidden

  .hero-bg
    height _vw(1304, $size-sp)
    +media('pc')
      height 860px

  .bg-dot
    absolute top -2.1% left _percentage(1476, $size-pc)
    size 873

    &::before
      transform rotate(22deg)

    +media('pc')
      top -2.6%
      left _percentage(1210, $size-pc)

  .hero-content
    color #000000
    position relative

    +media('pc')
      color #ffffff

    +media('pc')
      container-centering(_percentage($size-content-pc, $size-pc))

  .hero-slider
    container-centering(100%)
    overflow hidden
    z-index 1

  .hero-slides
    display flex
    position relative
    size 100%
    z-index 1

    .slide
      flex-shrink 0
      position relative
      size 100%
      overflow hidden

      &.is-selected
        z-index 1

      &.is-zoom-img
        img
          transform scale(1.12)

      img
        size 100%
        transition transform 12s linear

  .hero-copy
    +media('sp')
      margin _half(92) _vw(30, $size-sp) 0 _vw(48, $size-sp)
      position relative

    +media('pc')
      absolute bottom 34px left 39px
      z-index 1

  .hero-heading
    margin-bottom 13px
    width _half(494)

    +media('pc')
      margin 0 0 23px 2px
      width 419px

    @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
      width _half(418)

  .hero-text
    font-vw(28, 48)
    font-weight 500

    +media('pc')
      font-rem(18, 30)
    @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
      font-rem(16, 28)

  .bg-woods
    absolute top _half(-18) right _half(60)

  // ==========================================================================
  // 森友会が大切にしていること
  // ==========================================================================
  .section-philosophy
    margin-bottom _half(140)

    +media('pc')
      margin-bottom 140px

    .bg-1
      +media('sp')
        absolute top _vw(-154, $size-sp) left _vw(15, $size-sp)

      +media('pc')
        absolute top -33px right 114px

      @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
        right _half(114)

    .bg-2
      +media('sp')
        absolute top _vw(230, $size-sp) right

      +media('pc')
        absolute top 212px left 0px

    .bg-3
      +media('sp')
        absolute top _vw(990, $size-sp) right _vw(116, $size-sp)

      +media('pc')
        absolute top 474px right 69px

    .heading
      display table
      margin 0 auto 15px
      position relative

      &::before
        font-vw(16, 16, 200)
        absolute top -14px left 4px
        content 'しんゆうかい'
        display block

      +media('pc')
        margin 0 auto 23px

        &::before
          font-rem(12, 12)
          absolute top -17px left 3px

    .text-lead
      font-vw(28, 44)
      margin-bottom 26px
      font-weight 500

      +media('pc')
        font-rem(16, 28)
        margin-bottom 54px

    .philosophy-list
      flex-space-between()
      // margin-bottom _half(102)
      margin-bottom _half(150)
      position relative

      +media('sp')
        flex-direction column

      +media('pc')
        container-centering(_percentage(840, $size-content-pc))
        max-width 840px
        margin-bottom 137px

      .item
        position relative
        size _half(360)
        text-align center

        +media('sp')
          &:not(:first-child)
            margin-top 81px

        @media only screen and (max-width: 320px)
          &:not(:first-child)
            margin-top 160px

        &::before
          absolute top right left
          background #ffffff
          border-radius 50%
          content ''
          display block
          margin 0 auto
          size 100%

        +media('pc')
          // size 240
          max-width 240px
          width _percentage(240, 840)
          &::before
            size 240

        @media only screen and (min-width: $break-point-pc px) and (max-width: 1199px)
          width 200px
          &::before
            size 200
        @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
          width 150px
          &::before
            size 150

      .item-inner
        position relative
        width 100%

      .number, .text, .img
        display block

      .number
        font-vw(72, 72)
        color #f4b339
        font-family $font-lato
        font-weight bold
        margin 28px 0 9px

        +media('pc')
          font-rem(48, 48)
          margin 35px 0 11px
        @media only screen and (min-width: $break-point-pc px) and (max-width: 1199px)
          font-rem(40, 40)

        @media only screen and (min-width: $break-point-pc px) and (max-width: 1023px)
          font-rem(30, 30)


      .text
        font-vw(36, 51)
        font-weight bold

        +media('pc')
          font-rem(24, 34)

        @media only screen and (min-width: $break-point-pc px) and (max-width: 1199px)
          font-rem(20, 30)

        @media only screen and (min-width: $break-point-pc px) and (max-width: 1023px)
          font-rem(16, 26)

      .img
        text-align center

      .item-1
        .img
          margin 13px 8px 0 0

          img
            width _half(194)

          +media('pc')
            margin 19px 8px 0 0

            img
              width 126px

      .item-2
        +media('sp')
          absolute top _vw(256, $size-sp) right
          margin 0 !important

        @media only screen and (max-width: 320px)
          top _vw(380, $size-sp)

        .img
          margin 22px 3px 0 0

          img
            width _half(300)

          +media('pc')
            margin 32px 8px 0 0

            img
              width 201px

      .item-3
        .img
          margin 3px 0 0 10px

          img
            width _half(262)

          +media('pc')
            margin 7px 0 0 12px

            img
              width 167px

    .button-primary
      width _vw(570, $size-sp)

      +media('pc')
        width 300px

      .label
        margin-left 7px

        +media('pc')
          margin-left -14px

      .icon
        right 13px

        +media('pc')
          right 12px

  // ==========================================================================
  // 施設のご紹介
  // ==========================================================================
  .section-facilities
    margin-bottom _half(140)

    +media('pc')
      margin-bottom 140px

    .box-body
      padding 44px 0 _half(90)

      +media('pc')
        padding 59px 60px 61px

    .heading
      margin-bottom 15px

      +media('pc')
        margin-bottom 25px

    .text-lead
      font-vw(28, 44)
      margin-bottom 26px
      font-weight 500

      +media('pc')
        font-rem(16, 24)
        margin-bottom 45px

    .facilities-items
      +media('sp')
        margin 0 _vw(60, $size-sp)

      +media('pc')
        flex-space-between()

      .item
        text-align center

        +media('sp')
          &:not(:first-child)
            margin-top _half(90)

        +media('pc')
          max-width 296px
          width _percentage(296, 948)

      .img
        margin-bottom _half(30)

        +media('pc')
          margin-bottom 24px

      .button-secondary
        width _vw(370, $size-sp)

        +media('pc')
          width 176px

        @media only screen and (min-width: $break-point-pc px) and (max-width: 959px)
          width 100%

  // ==========================================================================
  // 採用情報
  // ==========================================================================
  .section-recruit
    margin-bottom 68px

    +media('pc')
      margin-bottom 139px

    .bg
      bg-img($image-path + 'img-7.jpg', center, center, no-repeat, , cover)
      size 100% _vw(360, $size-sp)

      +media('pc')
        bg-img($image-path + 'pc/img-7.jpg', center, center, no-repeat, , cover)
        background-attachment fixed
        height 550px

    .box-primary
      margin _vw(-94, $size-sp) 0 0 0

      +media('pc')
        margin -126px 0 0 0

    .box-content
      padding-top 45px

      +media('pc')
        padding-top 58px

    .heading
      margin-bottom 15px

      +media('pc')
        margin-bottom 25px

    .text-lead
      font-vw(28, 44)
      margin-bottom 27px
      font-weight 500

      +media('pc')
        font-rem(16, 24)
        margin-bottom 35px

    .recruit-link
      margin-bottom _half(90)

      +media('pc')
        margin-bottom 59px

    .button-primary
      width _vw(570, $size-sp)

      +media('pc')
        width 300px

        .icon
          right 14px

    .img
      +media('pc')
        relative top 1px

        img
          width 100%

    .img-web-interview
      absolute top _vw(-138, $size-sp) right _vw(-15, $size-sp)
      width _vw(270, $size-sp)
      z-index 2

      +media('pc')
        absolute top -34px right -25px
        max-width 241px
        width _percentage(240, 1068)

  .bottom-img
    bg-img($image-path + 'img-9.jpg', center, center, no-repeat, , cover)
    size 100% _vw(360, $size-sp)
    margin-top 0

    +media('pc')
      bg-img($image-path + 'pc/img-9.jpg', center, center, no-repeat, , cover)
      background-attachment fixed
      height 550px

  .button-back-to-top
    +media('pc')
      bottom 612px

